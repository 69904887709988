.mainNavbar{
    /* background-color: black; */
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1; 
    height: auto;   
    transition: 200ms; 
    /* background-image: linear-gradient(to right,rgb(66, 66, 66) 0, rgb(41, 41, 41) 20%,black 100%); */
}
.mainNavbar--hidden{
    transform: translateY(-74px);
    transition: 1500ms; 
}
.nav-item{
    cursor: pointer;
}
 .navColor{
    background: linear-gradient(to right,rgb(66, 66, 66) 0, rgb(41, 41, 41) 20%,black 100%);
 }
.navbar{
    /* background: black; */
    color: white;
}
.navbar-nav{
    margin-left:15% ;
    gap: 30px;
}
.navbar-nav .dropdown-menu{
    background: black;
    width: fit-content;
    margin: auto; 
}
.navbar-nav .dropdown-menu li>a{
    color:white
}
.navbar-nav .dropdown-menu li>a:hover{
    color:black
}
#navbarSupportedContent{
    transition: 1000ms;
}
 
/* Home Style Start  */

.home{
    background: linear-gradient(169.33deg, #100F0F 16.04%, #342F2F 91.8%); 
  
}
.home>p{
    color: white;
}
.homeBanner{
    width: 90%;
    height: 55vh;
    display: grid;
    align-items: center;
    margin: auto;
    border-radius: 10px;
    background-image: url(../Images/home/home_banner.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

}
#button{
    background: #D81818;
box-shadow: inset 8px 8px 6px #EC2E2E, inset 0px -5px 4px rgba(0, 0, 0, 0.25);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}
#button2{
background: #D81818;
box-shadow: inset 8px 8px 6px #EC2E2E, inset 0px -5px 4px rgba(0, 0, 0, 0.25);
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); 
color: rgb(252, 252, 252);
}
#button2:hover{
    background: #ffffff;
    box-shadow: inset 8px 8px 6px #ffffff, inset 0px -5px 4px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); 
    color: black;
    }
#button2LogOut{
    background: #ffffff;
    box-shadow: inset 8px 8px 6px #ffffff, inset 0px -5px 4px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); 
    color: rgb(0, 0, 0);
}
#button2LogOut:hover{ 
    background: #D81818;
    box-shadow: inset 8px 8px 6px #EC2E2E, inset 0px -5px 4px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); 
    color: rgb(255, 255, 255);
 }
.btnWhyAris{
    max-width:450px ;
    padding: 30px 40px;
    border-radius: 150px;
    background:#453e3e ;
    color: white;
    font-size: 18px;
    margin: 10px 0;
    box-shadow: inset 0px 8px 4px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); 
}
.btnWhyAris:hover{
    background: red;
}
.ourNetwork{
    background: linear-gradient(#282525 20%,red 20%);
}
.ourNetworkAdd{
    width:90%;
    margin:auto;
    border-radius:130px;
    background:#2E2E2E; 
    box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25), inset 8px 8px 6px #3F3E3E, inset 0px -7px 4px #201D1D;
}
.ourNetworkImg{
    margin: 0 20px;
}
.react-multi-carousel-item{
    height: 200px !important;
}
.react-multiple-carousel__arrow{
    top: 28% !important;
}
.react-multi-carousel-dot button{
    width: 9px !important;
    height: 9px !important;
}
.testimonialsCarousel{
    height: 650px;
    position: relative;
}
.react-3d-carousel .slider-container .slider-right div{
    background-image: url('.././Images/carousel/right.png');
    background-size: 100% 100%;
    background-repeat: no-repeat; 
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
    position: absolute !important;
    left:20% !important;
    top: 50% !important;
    padding: 10px ;
    color: black !important;
    border: none !important;;
}
.react-3d-carousel .slider-container .slider-left div{
    background-image: url('.././Images/carousel/left.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
    position: absolute !important;
    left:-70% !important;
    top: 50% !important; 
    padding: 10px;
    color: black !important;
    border: none !important;
}
.slider-single-content{
    border-radius: 216px;
    margin:  auto !important;
}

/* contact page */
#contactInputForm{
    margin-top:20px ;
border-radius: 150px;
 color: white;
background: #484C55;
border: 1.3px solid #494E59;
box-shadow: 0px 5.2px 5.2px rgba(0, 0, 0, 0.25), inset 0px 5.2px 5.2px rgba(0, 0, 0, 0.25);
}
input#fileInputForm{
    display: none;
}
 
#fileInputForm{
    margin-top:20px ;
border-radius: 150px;
 color: white;
background: #484C55;
border: 1.3px solid #494E59;
box-shadow: 0px 5.2px 5.2px rgba(0, 0, 0, 0.25), inset 0px 5.2px 5.2px rgba(0, 0, 0, 0.25);
}
#numContactInputForm{
    /* border-radius: 150px; */
 color: white;
background: #484C55;
border: 1.3px solid #494E59;
box-shadow: 0px 5.2px 5.2px rgba(0, 0, 0, 0.25), inset 0px 5.2px 5.2px rgba(0, 0, 0, 0.25);
}
 ::placeholder{
    color: rgb(147, 144, 144) !important;
}
#search{
    /* border-radius: 150px; */
 color: white;
background: #484C55;
border: 1.3px solid #494E59;
box-shadow: 0px 5.2px 5.2px rgba(0, 0, 0, 0.25), inset 0px 5.2px 5.2px rgba(0, 0, 0, 0.25);
}
 ::placeholder{
    color: rgb(147, 144, 144) !important;
}
.latLong{
    color: white;
    background: #484C55;
    border: 1.3px solid #494E59;
    box-shadow: 0px 5.2px 5.2px rgba(0, 0, 0, 0.25), inset 0px 5.2px 5.2px rgba(0, 0, 0, 0.25);
}

/* about us */
.aboutUsAris{
    background: linear-gradient(#282525 60%,red 20%);
}
.ourVisionbtn{
    width:100% ;
    padding: 30px 40px;
    border-radius: 150px;
    background:#453e3e ;
    color: white; 
    margin: 10px 0;
    box-shadow: inset 0px 8px 4px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); 
}
.ourVisionbtn:hover{
    background:red ;
}
/* -----blank page ---------- */
.blankSection{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh !important ;
    background: white;
    transition-delay:1.2s ;
}
.blankBox{
    position: relative;
    max-width:600px ;
}
.started{
    position: relative;
    top: 150px;
}
.clipPath-circle-lp{
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    transition: clip-path 1.2s ease-out;
    clip-path: circle(7.1% at 49.9% 100%);
}
.clipPath-circle-lp.active-circle{
    clip-path: circle(100.1% at 50% 100%);

} 
.clipPath-circle-pc{
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    transition: clip-path 1.2s ease-out;
    clip-path: circle(5.5% at 50% 100%);
}
.clipPath-circle-pc.active-circle{
    clip-path: circle(100.1% at 50% 100%);

}
.blankBtn{
    position: absolute;
    bottom: 0;  
    left: 48.5%; 
    margin: auto;
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
.clipPath-circle-pc{
    
    display: block;
}
.clipPath-circle-lp{
    display: none;
}
 
/* -------log in page----- */
.swal2-actions{
    display: none !important;
}
/* -----------------dashboard css---------- */
.circulCardDiv{
    display: flex ;
    align-items: center;
    flex-wrap: wrap;
    /* grid-template-columns: 24% 24% 24% 24%; */
    gap: 20px;
    justify-content: center;
}

.circulCard{
cursor: pointer;
width: 160px;
height: 160px;
margin: auto;
background: #373232;
box-shadow: 0px 4.4352px 4.4352px rgba(0, 0, 0, 0.25), inset 7.7616px 8.8704px 4.4352px #463E3E, inset -5.544px -4.4352px 4.4352px rgba(0, 0, 0, 0.25);
backdrop-filter: blur(7.2072px); 
border-radius: 252.806px;
padding: 20px;
display: grid;
align-items: center;
border-radius: 150px;
}
.userHName{
    font-size: 30px;
}
.jointEducationCollaps{
    background: #595f6c ;
    border-radius:20px;
    padding: 10px 0;
}
.jointEducationCollaps > div{
    color: rgb(147, 144, 144);
    padding: 5px ;
    padding-left:20px 
}
.jointEducationCollaps > div:hover{
    border-left: 5px solid red;
    background: #3a3e47 ;
    padding: 5px ;
    padding-left:20px; 
    color:white;
}
.react-datepicker__input-container > input{
    border: none;
    width: 100%;
    padding: 8px 20px;
    /* margin-top:20px ; */
    border-radius: 150px;
     color: white;
    background: #484C55; 
    border: 1.3px solid #494E59;
    box-shadow: 0px 5.2px 5.2px rgba(0, 0, 0, 0.25), inset 0px 5.2px 5.2px rgba(0, 0, 0, 0.25);
}
/* report police */

.reportPolice{
    background: #595f6c ;
    border-radius:20px;
    padding: 10px 0;
}
.reportPolice > div{
    color: rgb(147, 144, 144);
    padding: 5px ;
    padding-left:20px 
}
.reportPolice > div:hover{
    border-left: 5px solid red;
    background: #3a3e47 ;
    padding: 5px ;
    padding-left:20px; 
    color:white;
}
/* claim */

.claimType{
    background: #595f6c ;
    border-radius:20px;
    padding: 10px 0;
}
.claimType > div{
    color: rgb(147, 144, 144);
    padding: 5px ;
    padding-left:20px 
}
.claimType > div:hover{
    border-left: 5px solid red;
    background: #3a3e47 ;
    padding: 5px ;
    padding-left:20px; 
    color:white;
}
.popup{
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.9);
    height: 100vh;
    width: 100%;
    z-index: 100;
    display: none;
}
 
.myPolicyss{
    color: white;
}
.myPolicyss.hover{
    animation:  shake 0.8s  ;
    color: red;
}

@keyframes shake{
    0%{
      transform: translateX(0)
    }
    25%{
      transform: translateX(25px);
    }
      
    50%{
      transform: translateX(-25px);
    }
    100%{
      transform: translateX(0px);
    }
  }


  /* ---------popup------------ */
  .swal2-title{
    font-size: 16px !important;
  }
  .swal2-html-container {
    height: 100%;
  }
  .swal2-container.swal2-center>.swal2-popup {
    background-color: #282525;
    color: white;
    font-size: 14px;
  }
  .swal2-icon.swal2-info.swal2-icon-show {
    width: 40px;
    height: 40px;
  }
  .swal2-icon.swal2-info.swal2-icon-show .swal2-icon-content {
    animation: swal2-animate-i-mark .8s;
    font-size: 25px;
    font-weight: 700;

    /* ---- */

}
.swal2-icon.swal2-error.swal2-icon-show {
    width: 40px;
    height: 40px;
}
.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
    font-size: 8px;
}
.swal2-icon.swal2-success .swal2-success-ring { 
    width: 40px !important;
    height: 40px !important;
}
.swal2-icon.swal2-success {
    border-color: #a5dc86;
    color: #a5dc86;
    width: 40px;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
    animation: swal2-animate-success-line-tip .75s;
    font-size: 8px;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
    animation: swal2-animate-success-line-long .75s;
    font-size: 8px;
}
.contactInputForm{
    z-index:0 !important;
}

 

/* --------------------------------- */
 
@media(max-width:990px){
    .navbar-nav{
        margin-left:0 ;
        gap: 0px;
    }
    .homeBanner{
        background:white;
        border-radius: 20px !important;  
    }
    /* -----blanck page ---- */
    .blankBtn{
        position: absolute;
        bottom: 0;   
        left: 48%;
    }
    .clipPath-circle-pc{
        clip-path: circle(10.5% at 49.9% 100%);
        display: block;
    }
    .clipPath-circle-lp{
        display: block;
    }
    /* dashboard */
    .userHName{
        font-size: 25px;
    }
}
@media(max-width:750px){
    .circulCardDiv{
        display: grid ;
        align-items: center;
        grid-template-columns: 50% 50%;
        gap: 20px;
        justify-content: center;
    }
}
@media(max-width:600px){
    .testimonialsCarousel{
        height: 750px;
        position: relative;
    }
    .ourNetworkAdd{
        width:70%;
        margin:auto;
        border-radius:130px;
        background:#2E2E2E; 
        box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25), inset 8px 8px 6px #3F3E3E, inset 0px -7px 4px #201D1D;
    }
    .ourNetworkImg{
        margin: 0 -15px;
    }
    /* blank page */
    .blankBtn{
        position: absolute;
        bottom: 0;   
        left: 46%;
    }
    /* dashboard */
    .userHName{
        font-size: 16px;
    }
}
